.projectList {


    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    position: center;
    margin-right:auto;
    margin-left: auto;
    box-sizing: border-box;
    max-width: 1440px;

}
.card{
    justify-content:center;
}
.technologies{
    position: left;
    text-align:left;
    margin-right:auto;
    margin-left: auto;
    box-sizing: border-box;
    -webkit-column-count: 4;
    /* -moz-column-count: 4; */
         column-count: 4;
 -webkit-column-width: 200px;
    /* -moz-column-width: 200px; */
         column-width: 200px;
}

  
