.fa {
  padding: 200px;
  font-size: 400px;
  width: 50px;
  height:50px;
  text-align: center;
  text-decoration-color: white;
  border-radius: 20%;

}
  
  /* Add a hover effect if you want */
  .fa:hover {
    opacity: 0.9;
  }

  .fa-facebook {
    background: #3B5998;
    color: white;
    text-decoration-color: white;
  }
  .fa-linkedin {
    background: #0077B5;
    color: white;
  }
  .fa-github {
    background: black;
    color: white;
  }
  .fa-envelope {
    background: rgb(61, 199, 245);
    color: white;
  }