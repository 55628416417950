.App {
  text-align: center;

}

.jumbotron {
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}





.boundaries {

    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    position: center;
    margin-right:auto;
    margin-left: auto;
    box-sizing: border-box;
    max-width: 1200px;
    margin:70px auto;


}

.App {
  background: url("pexels.jpg") repeat-x;
}











@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
* {
  font-family: 'Roboto Condensed', sans-serif;
}
body {
  margin: 0;
}
.parallax-wrapper {
  perspective: 1px;
  transform-style: preserve-3d;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.background {
  background-position: center;
  background-size: cover;
  display: flex;
  flex: 1 0 auto;
  position: relative;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  transform: translateZ(-1px) scale(2);
}
.background--spring {
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/d/da/Precincts_of_Watari-jinja_shrine_in_Spring_season_2.JPG');
}
.background--summer {
  background-image: url('https://images.pexels.com/photos/375152/summer-sun-blooming-season-375152.jpeg?cs=srgb&dl=beautiful-bloom-blossom-375152.jpg&fm=jpg');
}
.background--fall {
  background-image: url('https://images.pexels.com/photos/33060/road-forest-season-autumn.jpg?cs=srgb&dl=autumn-fall-forest-33060.jpg&fm=jpg');
}
.background--winter {
  background-image: url('https://images.pexels.com/photos/235595/pexels-photo-235595.jpeg?cs=srgb&dl=branches-cold-season-235595.jpg&fm=jpg');
}
.title {
  position: absolute;
  margin: 0;
  top: calc(50% - 50px);
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5px;
  height: 100px;
  color: white;
  font-size: 5rem;
  background: rgba(0,0,0,0.4);
}

.season-desc {
  z-index: 1;
  position: relative;
  background: #282E34;
  color: white;
  font-size: 1.1rem;
  letter-spacing: 2px;
  line-height: 2em;
  padding: 3rem;
  height: 100vh;
  margin: 0;
  display: flex;
  
}
  .desc-wrapper {
    margin: auto;
    box-sizing: border-box;
  }
  .season--title {
    text-align: center;
    font-size: 4rem;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin: 20px 0;
  }
  .season--about {
    margin-top: 50px;
    padding: 0;
  }

@media (max-width: 998px) and (orientation: portrait) {
  .season-desc {
    padding: 1.5rem 20px;
    font-size: 1rem;
    height: unset;
    min-height: 100vh;
  }
    .season-title {
      font-size: 2.5rem;
      margin: 20px 0;
      letter-spacing: unset;
     }
    .season-about {
      margin-top: 20px;
     }
   }
 






















@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

}
