
.wrapper { 
    border : 2px solid #000; 
    overflow:hidden;
  }
  
  .wrapper div {
     min-height: 200px;
     padding: 10px;
  }
  #one {
    background-color: gray;
    float:left; 
    margin-right:20px;
    width:200px;
    border-right:2px solid #000;
  }
  #two { 
    background-color: white;
 


    min-height:170px;
  }
  
  @media screen and (max-width: 400px) {
     #one { 
      float: none;
      margin-right:0;
      width:auto;
      border:0;
      border-bottom:2px solid #000;    
    }
  }