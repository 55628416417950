.card3{
    
    border: solid 1px #555;
    box-shadow: 10px -10px 5px  rgba(0,0,0,0.6);
    /* -moz-box-shadow: 10px -10px 5px  rgba(0,0,0,0.6); */
    -webkit-box-shadow: 10px -10px 5px  rgba(0,0,0,0.6);
    -o-box-shadow: 10px -10px 5px  rgba(0,0,0,0.6);
    border-radius:25px;
    position: relative;
    margin: 20px;
    box-sizing: border-box;
    max-width: 1420px;

    background-color: white;
    opacity:0.95

}

.marg {



     bottom:10;
     margin:0px;
     position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
     right:30px;

}
.minHeight{
    min-height:400px;
}
.buffer {
    position: relative;

    
     bottom:10;

     padding-top:56.25%;

    margin: 20px;
    box-sizing: border-box;
     
     
}

.card2{
    
    border: solid 1px #555;

    box-shadow: 10px -10px 5px  rgba(0,0,0,0.6);
    /* -moz-box-shadow: 10px -10px 5px  rgba(0,0,0,0.6); */
    -webkit-box-shadow: 10px -10px 5px  rgba(0,0,0,0.6);
    -o-box-shadow: 10px -10px 5px  rgba(0,0,0,0.6);
    border-radius:25px;
    position: center;
    margin: 20px;
    box-sizing: border-box;
    max-width: 1420px;

    background-color: white;
    opacity:0.95

}
.card{
    
    position: center;
    margin: 20px;
    box-sizing: border-box;
    max-width: 1420px;
    opacity:1
}

