.navbar {
    display: flex;
    color: #343a40;

    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
    width: 100%;
    position: sticky;
    box-sizing: border-box;
  }
  
  .navbar-sticky {
    background: #343a40;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 1px 1px 1px rgb(255, 255, 255);
    animation: moveDown 0.5s ease-in-out;
  }
  
  .navbar--logo {
    width: 4rem;
    height: 2rem;
    margin-right: 0.5rem;
    animation: rotate 0.7s ease-in-out 0.5s;
  }
  
  @keyframes moveDown {
    from {
      transform: translateY(-5rem);
    }
    to {
      transform: translateY(0rem);
    }
  }
  
  @-moz-keyframes rotate {
    from { -moz-transform: rotate(360deg); }
    to { -moz-transform: rotate(0deg); }
}
@-webkit-keyframes rotate {
    from { -webkit-transform: rotate(360deg); }
    to { -webkit-transform: rotate(0deg); }
}
@keyframes rotate {
    from {transform:rotate(360deg);}
    to {transform:rotate(0deg);}
}

  